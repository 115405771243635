import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

const News = () => (
  <Layout>
    <SEO title="News" />

    <section className="section py-8  px-8 pb-9">
      <div className="container-fluid">
        <h5 className="h2 text-uppercase text-underline font-weight-bold text-primary text-center">
          University of Rwanda 2019 Graduation!
        </h5>
        <div className="py-8 justify-content-between">
          <div className="row row-grid justify-content-center align-items-center">
            <div className="col-lg-10 order-lg-1">
              <p className="h3 font-weight-bold">
              Study in Rwanda attended the University of Rwanda graduation at their Huye campus to celebrate hundreds of graduands. Parents, Guest speakers, UR Alumni, Members of UR, faculty, staff, and members of University of Rwanda Council, the University Senate, Management Board, and the Graduation Committee had all gathered in one accord.
              </p>
              <div className="row justify-content-between">
                <div className="col col-md">
                  <figure>
                    <img src={require("./../../images/downloads/49034635873_d0951aaf75_o.jpg")} alt="Image" className="img-fluid rounded" />
                  </figure>
                </div>
              </div>
              <p className=" my-4">
              Our study in Rwanda team got to interact with students graduating on that day. Some of the reviews we got from them told us more about how the University had impacted them.
              </p>
              <p className="my-4">
               <i>“I joined the University with the mind-set that I was old enough and had the knowledge, but the journey through as I learned the minor and major in class and out of class, I realised just how young I was, how hungry I was for more knowledge.”
                 <br /><b>Akinyeneye Felsite,</b> who graduated with Bachelor’s degree in Accounting.
               </i>
              </p>
              <div className="row justify-content-between">
                <div className="col col-md">
                  <figure>
                    <img src={require("./../../images/49035306522_c05f7d65aa_o.jpg")} alt="Image" className="img-fluid rounded" />
                  </figure>
                </div>
              </div>
              <p className="my-4">
              This cerebration did not come without a price from the graduands, from the administrators and the whole University of Rwanda family. The UR faculty and staff, who have had so much faith in the university of Rwanda had worked so hard to make the day a success.
              </p>
              <div className="row justify-content-between">
                <div className="col col-md">
                  <figure>
                    <img src="https://ur.ac.rw/IMG/jpg/untitled-11.jpg" alt="Image" className="img-fluid rounded" />
                  </figure>
                </div>
                <div className="col-12 col-md">
                  <figure>
                    <img src={require("./../../images/49035254257_378ca7389f_o.jpg")} alt="Image" className="img-fluid rounded" />
                  </figure>
                </div>
              </div>
              <p>Be part of this success! Visit <a href="sir.rw" target="_blank">sir.rw</a> to apply!</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default News
